import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styles from './style.module.scss'
import ProductCard from './ProductCard'

function ProductIntro(props) {
  const { productLists } = props
  return (
    <div className={styles.ProductContainer}>
      <div className={styles.CardWrap}>
        {productLists.map((item) => (
          <ProductCard key={item.img} data={item} />
        ))}
      </div>
    </div>
  )
}

ProductIntro.propTypes = {
  productLists: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
      url: PropTypes.string,
      closed: PropTypes.bool,
      coverImg: PropTypes.string,
    })
  ).isRequired,
}

export default memo(ProductIntro)
