import React, { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import Banner from './Banner'
import TimeLine from './TimeLine'
import Culture from './Culture'
import Mogul from './Mogul'
import MogulMob from './MogulMob'

export default function Company() {
  const { t } = useTranslation()
  const location = useLocation()
  const lng = location.pathname.split('/')[1]
  // 跳转页面指定位置

  const Section1 = useCallback(() => {
    const banners = [
      {
        id: 1,
        img: 'https://tc-cdn.123u.com/official-website/company/company_banner2.png',
        titleEn: lng === 'enUs' ? '' : 'About Huanle Entertainment',
        title: t('about huanle'),
        desc: t('about huanle desc'),
      },
    ]

    return (
      <section id="banner">
        <Banner banners={banners}></Banner>
      </section>
    )
  }, [lng, t])

  const Section2 = useCallback(() => {
    const yearsCN = [
      {
        year: '2020',
        block1: [
          {
            month: t('may'),
            text: t('2020 may'),
          },
        ],
        block2: [],
      },
      {
        year: '2010-2016',
        block1: [
          {
            month: '2016',
            text: t('2016'),
          },
        ],
        block2: [
          {
            month: '2010',
            text: t('2010'),
          },
        ],
      },
      {
        year: '2009',
        block1: [
          {
            month: t('july'),
            text: t('2009 july'),
          },
        ],
        block2: [],
      },
    ]

    const yearsOther = [
      {
        year: '2024',
        block1: [
          {
            month: t('march'),
            text: t('2024 march'),
          },
        ],
        block2: [],
      },
      {
        year: '2023',
        block1: [
          {
            month: t('april'),
            text: t('2023 april'),
          },
        ],
        block2: [],
      },
      {
        year: '2022',
        block1: [
          {
            month: t('september'),
            text: t('2022 september'),
          },
        ],
        block2: [
          {
            month: t('october'),
            text: t('2022 october'),
          },
        ],
      },
      {
        year: '2021',
        block1: [
          {
            month: t('november'),
            text: t('2021 november'),
          },
          {
            month: t('october'),
            text: t('2021 october'),
          },
        ],
        block2: [
          {
            month: t('june'),
            text: t('2021 june'),
          },
        ],
      },
      {
        year: '2020',
        block1: [
          {
            month: t('august'),
            text: t('2020 august'),
          },
          {
            month: t('july'),
            text: t('2020 july'),
          },
        ],
        block2: [
          {
            month: t('may'),
            text: t('2020 may'),
          },
        ],
      },
      {
        year: '2019',
        block1: [
          {
            month: t('december'),
            text: t('2019 december'),
          },
        ],
        block2: [
          {
            month: t('september'),
            text: t('2019 september'),
          },
        ],
      },
      {
        year: '2018',
        block1: [
          {
            month: t('december'),
            text: t('2018 december'),
          },
          {
            month: t('october'),
            text: t('2018 october'),
          },
        ],
        block2: [
          {
            month: t('may'),
            text: t('2018 may'),
          },
          {
            month: t('march'),
            text: t('2018 march'),
          },
        ],
      },
      {
        year: '2017',
        block1: [
          {
            month: t('february'),
            text: t('2017 february'),
          },
        ],
        block2: [
          {
            month: t('february'),
            text: t('2017 february2'),
          },
        ],
      },
      {
        year: '2010-2016',
        block1: [
          {
            month: '2016',
            text: t('2016'),
          },
          {
            month: '2015',
            text: t('2015'),
          },
          {
            month: '2013',
            text: t('2013'),
          },
        ],
        block2: [
          {
            month: '2012',
            text: t('2012'),
          },
          {
            month: '2010',
            text: t('2010'),
          },
        ],
      },
      {
        year: '2009',
        block1: [
          {
            month: t('july'),
            text: t('2009 july'),
          },
        ],
        block2: [],
      },
    ]
    return (
      <section
        className={`${styles.SectionContainer} ${styles.TimeLineContainer}`}
        id="timeline"
      >
        <div>
          <h3 className={styles.SectionTitle}>{t('big event')}</h3>
          <p></p>
        </div>
        <section>
          <TimeLine years={yearsOther}></TimeLine>
        </section>
      </section>
    )
  }, [t])

  const Section3 = useCallback(() => {
    const content = [
      {
        img: 'https://tc-cdn.123u.com/official-website/company/culture1.png',
        title: t('values1'),
        titleEn: lng === 'enUs' ? '' : 'Customer oriented',
        desc: [],
      },
      {
        img: 'https://tc-cdn.123u.com/official-website/company/culture3.png',
        title: t('values2'),
        titleEn: lng === 'enUs' ? '' : 'Creator based',
        desc: [],
      },
      {
        img: 'https://tc-cdn.123u.com/official-website/company/culture4.png',
        title: t('values3'),
        titleEn:
          lng === 'enUs' ? '' : 'Long-term persistence inward attribution',
        desc: [],
      },
      {
        img: 'https://tc-cdn.123u.com/official-website/company/culture5.png',
        title: t('values4'),
        titleEn: lng === 'enUs' ? '' : 'Express directly',
        desc: [],
      },
      {
        img: 'https://tc-cdn.123u.com/official-website/company/culture2.png',
        title: t('values5'),
        titleEn: lng === 'enUs' ? '' : 'Pursue the ultimate',
        desc: [],
      },
    ]
    return (
      <section
        className={`${styles.SectionContainer} ${styles.SectionCulture}`}
        id="culture"
      >
        <div>
          <h3>{t('huanle culture')}</h3>
          <p></p>
          <div className={styles.SectionCultureText}>
            <h4>{t('mission')}</h4>
            <p style={{ textAlign: 'center' }}>{t('brand content')}</p>
          </div>
          <div className={styles.SectionCultureText}>
            <h4>{t('vision')}</h4>
            <p style={{ textAlign: 'center' }}>{t('vision content')}</p>
          </div>
          <div className={styles.SectionCultureText}>
            <h4>{t('values')}</h4>
          </div>
          <Culture content={content}></Culture>
        </div>
      </section>
    )
  }, [t, lng])

  const Section4 = useCallback(() => {
    const users = [
      {
        id: 1,
        img: 'https://tc-cdn.123u.com/official-website/company/Steven2.png',
        name: t('ceo.name'),
        profession: t('ceo.profession'),
        idea: t('ceo.idea'),
        desc: t('ceo.desc'),
      },
      {
        id: 2,
        img: 'https://tc-cdn.123u.com/official-website/company/dahei_v3.png',
        name: t('dahei.name'),
        profession: t('dahei.profession'),
        idea: t('dahei.idea'),
        desc: t('dahei.desc'),
      },
      {
        id: 3,
        img: 'https://tc-cdn.123u.com/official-website/company/wangjiejie.png',
        name: t('stefanie.name'),
        profession: t('stefanie.profession'),
        idea: t('stefanie.idea'),
        desc: t('stefanie.desc'),
      },
      {
        id: 4,
        img: 'https://tc-cdn.123u.com/official-website/company/qiujie.png',
        name: t('siling.name'),
        profession: t('siling.profession'),
        idea: t('siling.idea'),
        desc: t('siling.desc'),
      },
      {
        id: 5,
        img: 'https://tc-cdn.123u.com/official-website/company/xuebin.jpg',
        name: t('xuebin.name'),
        profession: t('xuebin.profession'),
        idea: t('xuebin.idea'),
        desc: t('xuebin.desc'),
      },
    ]
    return (
      <section className={`${styles.SectionContainer}`} id="mogul">
        <div>
          <h3 className={styles.SectionTitle}>{t('huanle stars')}</h3>
          <p></p>
          <div>
            <Mogul users={users}></Mogul>
            <MogulMob users={users}></MogulMob>
          </div>
        </div>
      </section>
    )
  }, [t])

  const Section5 = () => (
    <section
      className={`${styles.SectionContainer} ${styles.TimeLineMobContainer}`}
      id="timeline-mobile"
    >
      <div>
        <h3 className={styles.SectionTitle}>{t('big event')}</h3>
        <p></p>
        <div>
          <img
            className={`${styles.TimeLineBg}`}
            src={
              lng === 'zhCn'
                ? 'https://tc-cdn.123u.com/official-website/mobile/company/timeline_v7.jpg'
                : 'https://tc-cdn.123u.com/official-website/mobile/company/timeline_v7_en.jpg'
            }
          ></img>
        </div>
      </div>
    </section>
  )

  return (
    <div className={styles.CompanyContainer}>
      <Section1></Section1>
      <Section2></Section2>
      <Section3></Section3>
      <Section4></Section4>
      <Section5></Section5>
    </div>
  )
}
