import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styles from './style.module.scss'

function NewsHead() {
  const { t } = useTranslation()
  const location = useLocation()
  const lng = location.pathname.split('/')[1]
  return (
    <div className={styles.NewsMainTitle}>
      <div className={styles.Title}>{t('news')}</div>
      {lng !== 'enUs' && <div className={styles.SubTitle}>News</div>}
    </div>
  )
}

export default memo(NewsHead)
