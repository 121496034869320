import React from 'react'
import styles from './style.module.scss'

const Report: React.FC = () => (
  <div className={styles.ReportContainer}>
    <div className={styles.HeaderPlaceholder}></div>
    <div className={styles.Huanhuan}></div>
    <div className={styles.Lele}></div>
    <div className={styles.Content}>
      <strong>廉正举报须知</strong>
      <div className="ace-line">
        “欢乐互娱”要求每位员工在工作中以合法合规、合乎道德的方式开展业务,对于任何舞弊行为,我们都将予以坚决打击。
      </div>
      <div className="ace-line ace-line">我司廉正举报受理以下举报:</div>
      <ol className="list-number1">
        <li className="ace-line ace-line" data-list="number">
          <div>
            ●
            受贿、索贿、挪用公款、侵占公司资产及徇私舞弊损害公司利益等违法违纪行为；
          </div>
        </li>
        <li className="ace-line ace-line" data-list="number">
          <div>● 违规接受礼品及款待等行为；</div>
        </li>
        <li className="ace-line ace-line" data-list="number">
          <div>● 利用职务之便为自己、外部关系人或他人谋取不正当利益；</div>
        </li>
        <li className="ace-line ace-line" data-list="number">
          <div>
            ●
            员工存在严重利益冲突行为，如员工违反规定投资、就职于上海欢乐互娱网络科技有限公司及其关联公司的供应商、商家、合作伙伴等;
          </div>
        </li>
        <li className="ace-line ace-line" data-list="number">
          <div>● 泄露公司保密信息等；</div>
        </li>
        <li className="ace-line ace-line" data-list="number">
          <div>● 其他违反法律法规及公司规定的行为。</div>
        </li>
      </ol>
      <br />
      <div className="ace-line ace-line">需要提供的信息：</div>
      <div className="ace-line ace-line">
        在您的举报中，请提供尽可能详细的信息，以便我们能够迅速而有效地调查。这些信息可能包括但不限于：
      </div>
      <ul className="list-bullet1">
        <li className="ace-line ace-line" data-list="bullet">
          <div>● 涉及的个人或部门；</div>
        </li>
        <li className="ace-line ace-line" data-list="bullet">
          <div>● 具体的违法或不当行为的描述；</div>
        </li>
        <li className="ace-line ace-line" data-list="bullet">
          <div>● 发生的时间和地点。</div>
        </li>
      </ul>
      <br />
      <div className="ace-line ace-line">
        <strong>举报电话：17721160238</strong>
      </div>
      <div className="ace-line ace-line">
        <strong>
          举报邮件：<a href="mailto://jubao@huanle.com">jubao@huanle.com</a>
        </strong>
      </div>
      <div className="ace-line ace-line">
        <strong>
          举报邮寄：上海市浦东新区张江镇张东路1158号礼德国际2号楼10楼
          收件人：合规监察中心
        </strong>
      </div>
      <br />
      <div className="ace-line ace-line">
        <strong>保密承诺：</strong>
      </div>
      <div className="ace-line ace-line">
        <strong>
          我司郑重承诺，对于所有举报信息，都将严格保密处理。我们将尽一切可能采取措施，确保举报者不受到任何负面影响。
        </strong>
      </div>
      <div className="ace-line ace-line">
        <strong>
          对于立案调查并有结论的举报,我们会根据您留下的联系方式进行反馈。
        </strong>
      </div>
    </div>
  </div>
)

export default Report
