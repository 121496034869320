import { combineReducers } from 'redux'
import { reducer as homeReducer } from '@/pages/Home/store'
import newsReducer from '@/pages/News/store/reducer'
import produce, { enableES5 } from 'immer'
import i18n from 'i18next'
import { getROOOfficial } from '@/utils'
import { StoreState } from './typings'

const lng = localStorage.getItem('i18nextLng') || 'zhCn'

enableES5()
const defaultState: StoreState = {
  menuList: [
    {
      name: i18n.t('company'),
      target: '_self',
      path: '/company',
      children: [
        {
          name: i18n.t('introduction'),
          path: '/company#banner',
        },
        {
          name: i18n.t('timeline'),
          path: '/company#timeline',
        },
        {
          name: i18n.t('culture'),
          path: '/company#culture',
        },
        {
          name: i18n.t('huanle stars'),
          path: '/company#mogul',
        },
      ],
    },
    {
      name: i18n.t('products'),
      target: '_self',
      path: '/product',
      children: [
        {
          name: i18n.t('jjsg mobile'),
          path: 'https://act.huanle.com/',
          target: '_blank',
        },
        {
          name: i18n.t('jjsg'),
          path: 'http://sg.huanle.com/',
          target: '_blank',
        },
        {
          name: i18n.t('roo'),
          path: getROOOfficial(lng),
          target: '_blank',
        },
        {
          name: i18n.t('ms'),
          path: 'http://my.4399.com/yxmsdzls/',
          target: '_blank',
        },
        {
          name: i18n.t('dn mobile'),
          path: '',
          target: '',
        },
      ],
    },
    {
      name: i18n.t('recruit'),
      path: '/hire/socialRecruit',
      children: [
        {
          name: i18n.t('social recruit'),
          target: '_blank',
          path: 'https://huanle.jobs.feishu.cn/society',
        },
        {
          name: i18n.t('school recruit'),
          target: '_blank',
          path: 'https://huanle.jobs.feishu.cn/campus',
        },
        // {
        //   name: '校园招聘',
        //   target: '_blank',
        //   path: '/campus/campusRecruit',
        // },
      ],
    },
    {
      name: i18n.t('news'),
      target: '_self',
      path: '/news',
      children: [
        {
          name: i18n.t('huanle activities'),
          path: '/news#industry',
        },
        {
          name: i18n.t('game information'),
          path: '/news#information',
        },
        {
          name: i18n.t('activities'),
          path: '/news#activities',
        },
        {
          name: i18n.t('annual'),
          path: '/news#annual',
        },
      ],
    },
    {
      name: i18n.t('contact'),
      path: '/contact',
      target: '_self',
      children: [],
    },
  ],
}

const common = (state: StoreState = defaultState): StoreState =>
  produce(state, () => {})

const rootReducer = combineReducers({
  home: homeReducer,
  news: newsReducer,
  common,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
