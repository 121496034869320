import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '@/store/reducer'
import { actionCreators } from '../store'

function useFetchDetail() {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const newsDetail = useSelector((state: AppState) => state.news.newsDetail)
  const [loading, setLoading] = useState(true)
  const language = localStorage.getItem('i18nextLng') || 'zhCn'

  useEffect(() => {
    dispatch(
      actionCreators.changeNewsDetail({
        content: '',
        other_info: {
          previous_info: { id: 0, title: '' },
          next_info: { id: 0, title: '' },
        },
      })
    )
    const fetchData = async () => {
      await dispatch(
        actionCreators.getNewsDetail({ id: Number(id), lng: language })
      )
      setLoading(false)
    }
    fetchData()
  }, [id, dispatch, language])

  return { newsDetail, loading }
}

export default useFetchDetail
