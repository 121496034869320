import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import styles from './style.module.scss'

export default function Culture(props) {
  const location = useLocation()
  const lng = location.pathname.split('/')[1]

  const removeOtherStyle = useCallback((dom, reset) => {
    // 获取容器最大宽度，动态计算
    if (reset) {
      const wrap = document.querySelector(`.${styles.CultureContainer}`)
      const wrapWidth = parseInt(window.getComputedStyle(wrap).width, 10)
      const domWidth = parseInt(
        window.getComputedStyle(dom.children[1]).width,
        10
      )
      dom.style.width =
        (wrapWidth - domWidth) / 400 < 0.3
          ? 0
          : `${(wrapWidth - domWidth) / 400}rem`
    } else {
      dom.style.width = ''
    }
    dom.children[0].style.width = ''
  }, [])

  const handleMouseLeave = useCallback(() => {
    const doms = document.querySelectorAll(`.${styles.CultureSection}`)
    for (let i = 0; i < doms.length; i++) {
      removeOtherStyle(doms[i], false)
    }
  }, [])

  const handleMouseEnter = useCallback((index) => {
    const doms = document.querySelectorAll(`.${styles.CultureSection}`)
    doms[index].style.width = window.getComputedStyle(
      doms[index].children[1]
    ).width
    doms[index].children[0].style.width = '0'
    for (let i = 0; i < doms.length; i++) {
      if (i !== index) {
        removeOtherStyle(doms[i], true)
      }
    }
  }, [])

  const lists = useCallback(
    (content) => content.map((one) => <li key={one}>{one}</li>),
    []
  )

  const { content } = props
  const itemLists = content.map(
    (one, index) => (
      <div
        key={one.title}
        className={styles.CultureSection}
        onMouseEnter={() => handleMouseEnter(index)}
      >
        <div>
          <img src={one.img}></img>
          <p
            className={`${styles.CultureTitle} ${
              lng === 'enUs' ? styles.CultureTitleLngEnUS : ''
            }`}
          >
            {one.title}
          </p>
          <p className={styles.CultureEnTitle}>{one.titleEn}</p>
        </div>
        <div>
          <img src={one.img}></img>
          <h4>{one.title}</h4>
          <p>{one.titleEn}</p>
          <ul>{lists(one.desc)}</ul>
        </div>
      </div>
    ),
    []
  )
  return (
    <div className={styles.CultureContainer} onMouseLeave={handleMouseLeave}>
      {itemLists}
    </div>
  )
}

Culture.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
}
