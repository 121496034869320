// import { exact } from 'prop-types'
// import { Redirect } from 'react-router'
import React from 'react'
import { Redirect } from 'react-router-dom'
import Layout from '@/components/Layout'
import Home from '@/pages/Home'
import SocialReruit from '@/pages/SocialRecruit'
import Product from '@/pages/Product'
import Company from '@/pages/Company'
import News from '@/pages/News'
import NewsDetail from '@/pages/News/NewsDetail'
import Contact from '@/pages/Contact'
import CampusRecruit from '@/pages/CampusRecruit'
import CampusLayout from '@/components/CampusLayout'
import HireLayout from '@/components/HireLayout'
import Presentation from '@/pages/Presentation'
import Protocol from '@/pages/Protocol'
import Report from '@/pages/Report'

let currentLang = localStorage.getItem('i18nextLng')
if (!currentLang) {
  currentLang = 'zhCn'
}

export default [
  {
    path: '/:lng/protocol/:gameCode/:code',
    component: Protocol,
  },
  {
    path: '/:lng/campus',
    component: CampusLayout,
    routes: [
      {
        path: '/:lng/campus/campusRecruit',
        exact: true,
        component: CampusRecruit,
      },
      {
        path: '/:lng/campus/presentation',
        exact: true,
        component: Presentation,
      },
    ],
  },
  {
    path: '/:lng/hire',
    component: HireLayout,
    routes: [
      {
        path: '/:lng/hire/socialRecruit',
        exact: true,
        component: SocialReruit,
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    routes: [
      {
        path: '/',
        exact: true,
        render: (): JSX.Element => <Redirect to={`/${currentLang}/home`} />,
      },
      {
        path: '/:lng/report',
        component: Report,
      },
      // {
      //   path: '/company',
      //   component: Company,
      // },
      // {
      //   path: '/product',
      //   component: Product,
      // },
      // {
      //   path: '/socialReruit',
      //   component: SocialReruit,
      // },
      // {
      //   path: '/contact',
      //   component: Contact,
      // },
      // {
      //   path: '/news',
      //   component: News,
      // },
      // {
      //   path: '/news/:id',
      //   component: NewsDetail,
      // },
      {
        path: '/:lng/home',
        component: Home,
      },
      {
        path: '/:lng/company',
        component: Company,
      },
      {
        path: '/:lng/product',
        component: Product,
      },
      {
        path: '/:lng/socialReruit',
        component: SocialReruit,
      },
      {
        path: '/:lng/contact',
        component: Contact,
      },
      {
        path: '/:lng/news',
        component: News,
      },
      {
        path: '/:lng/news-detail/:id',
        component: NewsDetail,
      },
      // {
      //   path: '/CampusRecruit',
      //   exact: true,
      //   component: CampusRecruit,
      // },
      {
        path: '*',
        render: (): JSX.Element => <Redirect to={`/${currentLang}/home`} />,
      },
    ],
  },
]
