import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

export default function MogulPerson(props) {
  const { t } = useTranslation()
  const { personInfo } = props
  return (
    <div className={styles.PersonInfoContainer}>
      <div>
        <div className={styles.PersonTop}>
          <div className={styles.PersonTopInfoTitle}>
            <h4>{personInfo.profession}</h4>
            <h4>{personInfo.profession}</h4>
          </div>
          <div>
            <p>
              <span>{t('name')}：</span>
              <span>{personInfo.name}</span>
            </p>
            <p>
              <span>{t('experience')}：</span>
              <span>{personInfo.desc}</span>
            </p>
          </div>
        </div>
        <div className={styles.PersonDesc}>
          <img
            src="https://tc-cdn.123u.com/official-website/company/quotation_left.png"
            draggable="false"
          ></img>
          <pre>{personInfo.idea}</pre>
          <img
            src="https://tc-cdn.123u.com/official-website/company/quotation_right.png"
            draggable="false"
          ></img>
        </div>
      </div>
      <div style={{ backgroundImage: `url(${personInfo.img})` }}></div>
    </div>
  )
}

MogulPerson.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  personInfo: PropTypes.object.isRequired,
}
