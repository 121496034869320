import React, {
  FC,
  useCallback,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { Tabs } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

const { TabPane } = Tabs

interface TabItemProps {
  chinese: string
  english: string
}

const TabItem: FC<TabItemProps> = (props: TabItemProps) => {
  const { chinese, english } = props
  const location = useLocation()
  const lng = location.pathname.split('/')[1]

  return (
    <div className={styles.TabItem}>
      <div>{chinese}</div>
      {lng !== 'enUs' && <div className={styles.SubTab}>{english}</div>}
    </div>
  )
}

const setHash = (hash: string, set: Dispatch<SetStateAction<string>>) => {
  if (hash) {
    set(hash.substring(1))
  } else {
    set('all')
  }
}

const NewsTab = () => {
  const [activeKey, setActiveKey] = useState('all')
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation()
  const lng = location.pathname.split('/')[1]

  useEffect(() => {
    const { hash } = window.location
    setHash(hash, setActiveKey)
    history.listen((url) => {
      const { hash: urlHash } = url
      setHash(urlHash, setActiveKey)
    })
  }, [history])

  const handleTabClick = useCallback(
    (key: string) => {
      setActiveKey(key)
      history.push(`/${lng}/news#${key}`)
    },
    [history, lng]
  )

  return (
    <div className={styles.NewsTab}>
      <Tabs
        defaultActiveKey="all"
        activeKey={activeKey}
        tabBarGutter={0}
        onTabClick={handleTabClick}
        centered
      >
        <TabPane
          tab={<TabItem chinese={t('all news')} english="MEDIA" />}
          key="all"
        />
        <TabPane
          tab={<TabItem chinese={t('huanle activities')} english="NEWS" />}
          key="industry"
        />
        <TabPane
          tab={
            <TabItem chinese={t('game information')} english="ANNOUNCEMENT" />
          }
          key="information"
        />
        <TabPane
          tab={<TabItem chinese={t('activities')} english="ACTIVITIES" />}
          key="activities"
        />
        <TabPane
          tab={<TabItem chinese={t('annual')} english="ANNUAL" />}
          key="annual"
        />
      </Tabs>
    </div>
  )
}

export default React.memo(NewsTab)
