import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Navigation, Pagination, Autoplay, EffectFade, EffectFlip, EffectCoverflow, EffectCube, Mousewheel,
} from 'swiper'
import styles from './style.module.scss'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade, EffectFlip, EffectCoverflow, EffectCube, Mousewheel])

function Banner(props) {
  const {
    bannerList,
    showBottomTabs,
    autoplay,
    effect,
    loop,
    showIndicator,
    showArrow,
    direction,
    mousewheel,
    fadeClass,
  } = props

  const [current, setCurrent] = useState(0)
  const [banner, setBanner] = useState(null)

  const onSwiper = useCallback((swiper) => {
    setBanner(swiper)
  })

  const onSlideChange = useCallback((swiper) => {
    setCurrent(swiper.realIndex)
  })

  const slideTo = useCallback((page) => {
    if (loop) {
      banner.slideTo(page + 1)
    } else {
      banner.slideTo(page)
    }
  })

  useEffect(() => {
    if (fadeClass) {
      $(`.${fadeClass}`).css({
        position: 'relative',
        opacity: 0,
        top: '30px',
      })
    }
  })

  const onSlideChangeTransitionEnd = useCallback(() => {
    if (fadeClass) {
      $(`.${fadeClass}`).animate({
        opacity: 1,
        top: 0,
      }, 300)
    }
  })

  return (
    <div className={styles.BannerContainer}>
      <Swiper
        className={`${styles.SwiperContainer} ${direction === 'vertical' && styles.SwiperContainerVertical}`}
        loop={loop}
        effect={effect}
        pagination={showIndicator && bannerList.length > 1 && { clickable: true }}
        navigation={bannerList.length > 1 && showArrow}
        autoplay={autoplay}
        onSlideChange={onSlideChange}
        onSwiper={onSwiper}
        direction={direction}
        mousewheel={mousewheel}
        onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
      >
        {
          bannerList.map((item) => (
            <SwiperSlide key={item.tabTitle}>
              <div className={styles.Carousel}>
                {item.type === 'image' && (
                  <picture>
                    <source className={styles.BackgroundImage} srcSet={item.url} media="(min-width:1025px)" />
                    <source className={styles.BackgroundImage} srcSet={item.mobileUrl} media="(max-width:1024px)" />
                    <img className={styles.BackgroundImage} src={item.url} />
                  </picture>
                )}
                {item.type === 'video'
                  && (
                    <video className={styles.BackgroundVideo} autoPlay loop muted poster={item.poster}>
                      <source src={item.url} type="video/mp4" />
                      <track></track>
                    </video>
                  )}
                <div className={styles.InnerContent}>
                  {item.content}
                </div>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
      {
        showBottomTabs && (
          <div className={styles.BottomTabs}>
            {bannerList.map((item, index) => (
              <div key={item.tabTitle} className={`${styles.BottomTab} ${current === index && styles.BottomTabSelected}`} onClick={() => slideTo(index)}>
                {item.tabTitle}
              </div>
            ))}
          </div>
        )
      }
    </div>
  )
}

Banner.propTypes = {
  bannerList: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    mobileUrl: PropTypes.string,
    tabTitle: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.element,
  })),
  showBottomTabs: PropTypes.bool,
  autoplay: PropTypes.bool,
  effect: PropTypes.string,
  loop: PropTypes.bool,
  showIndicator: PropTypes.bool,
  showArrow: PropTypes.bool,
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  mousewheel: PropTypes.bool,
  fadeClass: PropTypes.string,
}

Banner.defaultProps = {
  bannerList: [],
  showBottomTabs: false,
  autoplay: false,
  effect: '',
  loop: false,
  showIndicator: false,
  showArrow: false,
  direction: 'horizontal',
  mousewheel: false,
  fadeClass: null,
}

export default React.memo(Banner, (prevProps, nextProps) => {
  const { bannerList: prevList, ...prevOtherProps } = prevProps
  const { bannerList: nextList, ...nextOtherProps } = nextProps
  if (prevList.length !== nextList.length) {
    return false
  }
  const nextOtherPropsKeys = Reflect.ownKeys(nextOtherProps)
  for (let i = 0; i < nextOtherPropsKeys.length; i++) {
    const key = nextOtherPropsKeys[i]
    if (prevOtherProps[key] !== nextOtherProps[key]) {
      return false
    }
  }
  return true
})
