import React from 'react'
import PropTypes from 'prop-types'
import Banner from '@/components/Banner'
import { useSelector } from 'react-redux'
import { isWXBrowser } from '@/utils'
import { NotificationFilled } from '@ant-design/icons'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NewsIntro from './components/NewsIntro'
import BottomNavigator from './components/BottomNavigator'
import styles from './style.module.scss'

function Home() {
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation()
  const lng = location.pathname.split('/')[1]

  const bannerList = [
    {
      tabTitle: '111',
      type: 'video',
      url: 'https://tc-cdn.123u.com/official-website/home/home_video_v11.mp4',
      poster: 'https://tc-cdn.123u.com/official-website/home/poster_v7.jpg',
      content: (
        <div className={styles.Slogan}>
          {lng !== 'enUs' && (
            <div className={styles.SloganChinese}>{t('slogan')}</div>
          )}
          <div className={styles.SloganEnglish}>Joymaker</div>
        </div>
      ),
    },
  ]
  const mobileBannerList = [
    {
      tabTitle: '111',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/mobile/home/banner_v2.jpg',
    },
  ]

  const menuList = useSelector((state) => state.common.menuList)

  const testIsWxBrowser = isWXBrowser()

  return (
    <>
      {!testIsWxBrowser && (
        <div className={styles.PCBanner}>
          <Banner bannerList={bannerList} />
        </div>
      )}
      <div className={styles.MobileBanner}>
        <Banner bannerList={mobileBannerList} />
      </div>
      <NewsIntro />
      <BottomNavigator menuList={menuList} accordion />
      {/* <div
        className={styles.Notice}
        onClick={() => {
          history.push(`/news/116`)
        }}
      >
        <NotificationFilled className={styles.Icon} />
        关于近期不法分子攀附我司商誉从事违法行为的重要声明
      </div> */}
    </>
  )
}

Home.propTypes = {
  route: PropTypes.shape({ routes: PropTypes.arrayOf(PropTypes.object) })
    .isRequired,
}

export default React.memo(Home)
