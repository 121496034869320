import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../Dialog'
import styles from './style.module.scss'

export default function Mogul(props) {
  const { users } = props
  const [showDialog, setShowDialog] = useState(false)
  const [index, setIndex] = useState(0)

  const closeDialog = useCallback(
    (isShow, setShowDialog) => {
      setShowDialog(isShow)
    },
  )

  const changeIndex = useCallback(
    (index, setIndex) => {
      setIndex(index)
    },
  )

  const dialog = useCallback(
    (setShowDialog) => (
      <Dialog showDialog={showDialog} closeDialog={(isShow) => closeDialog(isShow, setShowDialog)} changeIndex={(index) => changeIndex(index, setIndex)} personInfo={users} index={index}></Dialog>
    ),
  )

  const handleClick = useCallback(
    (index) => {
      setShowDialog(true)
      setIndex(index)
    },
  )

  const itemLists = users.map((one, index) => (
    <div key={one.id} onClick={() => handleClick(index)}>
      <div style={{ backgroundImage: `url(${one.img})` }}></div>
      <p>{one.name}</p>
      <p>{one.profession}</p>
    </div>
  ))
  return (
    <div>
      <div className={styles.MogulContainer}>{itemLists}</div>
      <div>{dialog(setShowDialog)}</div>
    </div>
  )
}

Mogul.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
}
