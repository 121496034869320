import React, { memo, useMemo } from 'react'
import Banner from '@/components/Banner'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { getROOOfficial } from '@/utils'
import ProductIntro from './components/ProductIntro'
import styles from './style.module.scss'

function Product() {
  const { t } = useTranslation()
  const location = useLocation()
  const lng = location.pathname.split('/')[1]
  const bannerList = useMemo(() => {
    // if (lng === 'zhCn') {
    // return [
    //   {
    //     tabTitle: t('ms'),
    //     type: 'image',
    //     url: 'https://tc-cdn.123u.com/official-website/product/ms.png',
    //     mobileUrl:
    //       'https://tc-cdn.123u.com/official-website/mobile/product/ms.png',
    //     content: (
    //       <div className={`${styles.Description}`}>
    //         <div className={styles.Title}>{t('product ms.title')}</div>
    //         <div className={styles.SubTitle}>{t('product ms.subtitle')}</div>
    //         <div
    //           onClick={() => window.open('http://my.4399.com/yxmsdzls/')}
    //           className={styles.DetailBtn}
    //         >
    //           {t('product ms.detail')}
    //         </div>
    //       </div>
    //     ),
    //   },
    // ]
    // }
    return [
      {
        tabTitle: t('roo'),
        type: 'image',
        url: 'https://tc-cdn.123u.com/official-website/product/ro_v2.jpg',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/mobile/product/ro_v2.jpg',
        content: (
          <div className={`${styles.Description}`}>
            <div className={styles.Title}>{t('product roo.title')}</div>
            <div className={styles.SubTitle}>
              {t('product roo.subtitle.line1')}
              <br />
              {t('product roo.subtitle.line2')}
              <br />
              {t('product roo.subtitle.line3')}
              <br />
              {t('product roo.subtitle.line4')}
            </div>
            <div
              onClick={() => {
                window.open(getROOOfficial(lng))
              }}
              className={styles.DetailBtn}
            >
              {t('product roo.detail')}
            </div>
          </div>
        ),
      },
      {
        tabTitle: t('jjsg'),
        type: 'image',
        url: 'https://tc-cdn.123u.com/official-website/product/jjsg_v2.jpg',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/mobile/product/jjsg.png',
        content: (
          <div className={`${styles.Description}`}>
            <div className={styles.Title}>{t('product jjsg.title')}</div>
            <div className={styles.SubTitle}>{t('product jjsg.subtitle')}</div>
            <div
              onClick={() => window.open('http://sg.huanle.com/')}
              className={styles.DetailBtn}
            >
              {t('product jjsg.detail')}
            </div>
          </div>
        ),
      },
      {
        tabTitle: t('ms'),
        type: 'image',
        url: 'https://tc-cdn.123u.com/official-website/product/ms.png',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/mobile/product/ms.png',
        content: (
          <div className={`${styles.Description}`}>
            <div className={styles.Title}>{t('product ms.title')}</div>
            <div className={styles.SubTitle}>{t('product ms.subtitle')}</div>
            <div
              onClick={() => window.open('http://my.4399.com/yxmsdzls/')}
              className={styles.DetailBtn}
            >
              {t('product ms.detail')}
            </div>
          </div>
        ),
      },
      {
        tabTitle: t('dn mobile'),
        type: 'image',
        url: 'https://tc-cdn.123u.com/official-website/product/dn-banner.png',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/product/dn-banner-small.png',
        content: (
          <div className={`${styles.Description}`}>
            <div className={styles.Title}>{t('product dn mobile.title')}</div>
            <div className={styles.SubTitle}>
              {t('product dn mobile.subtitle')}
            </div>
          </div>
        ),
      },
    ]
  }, [lng, t])

  const productLists = [
    {
      img: 'https://tc-cdn.123u.com/official-website/product/ro-ck_v2.png',
      title: t('roo'),
      subTitle: t('product roo.title'),
      url: getROOOfficial(lng),
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/product/jjsg-ck_v2.png',
      title: t('jjsg'),
      subTitle: t('product jjsg.title'),
      url: 'http://sg.huanle.com/',
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/product/ms-ck.png',
      title: t('ms'),
      subTitle: t('product ms.title'),
      url: 'http://my.4399.com/yxmsdzls/',
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/product/dn-ck.png',
      title: t('dn mobile'),
      subTitle: t('product dn mobile.title'),
      url: '',
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/product/more-ck.png',
      title: t('coming soon'),
      subTitle: t('please wait'),
      url: '',
      closed: true,
    },
  ]

  return (
    <>
      <Banner bannerList={bannerList} autoplay showBottomTabs loop showArrow />
      <div className={styles.ImageWrap}>
        <img src="https://tc-cdn.123u.com/official-website/product/ourgames.png" />
        <div className={styles.OurGame}>
          <div className={styles.Title}>OUR GAMES</div>
          <div className={styles.SubTitle}>{t('slogan')}</div>
        </div>
      </div>
      <div id="product-card">
        <ProductIntro productLists={productLists} />
      </div>
    </>
  )
}

export default memo(Product)
