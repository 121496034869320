const apiList = {
  getHomeNewsList: 'GET /api/get-home-news-list',
  getNewsList: 'GET /api/get-news-list',
  getNewsDetail: 'GET /api/get-news-detail',

  getWXConfig: 'GET /api/get-wx-config',

  getProtocolDetail: 'GET /api/protocol',
}

export default apiList
