import React, { useCallback, useLayoutEffect } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import styles from './style.module.scss'
import MogulPerson from '../MogulPerson'

export default function Dialog(props) {
  const {
    showDialog, personInfo, index,
  } = props

  const moveBlock = useCallback(
    () => {
      // 改变切换图标状态
      const iconLeft = document.querySelectorAll(`.${styles.DialogArrow}`)[0]
      const iconRight = document.querySelectorAll(`.${styles.DialogArrow}`)[1]
      if (!iconLeft || !iconRight) {
        return
      }
      iconLeft.style.opacity = index === 0 ? 0 : 1
      iconRight.style.opacity = index === personInfo.length - 1 ? 0 : 1

      const slider = document.querySelector(`.${styles.PersonSlider}`)
      // 获取单个元素宽度
      const width = parseInt(window.getComputedStyle(slider.children[index]).width, 10)
      const move = width * index
      slider.style.transform = `translateX(-${move}px)`
    },
  )

  const handleClickArrow = useCallback(
    (to) => {
      // 获取已移动的距离
      if (to === 'left') {
        if (index === 0) {
          return
        }
        props.changeIndex(index - 1)
      } else {
        if (index === personInfo.length - 1) {
          return
        }
        props.changeIndex(index + 1)
      }
      moveBlock()
    },
  )

  const personLists = personInfo.map((one) => (
    <div key={one.id} className={styles.PersonSliderOne}>
      <MogulPerson personInfo={personInfo[index]}></MogulPerson>
    </div>
  ))

  useLayoutEffect(() => {
    moveBlock()
  }, [index])

  return (
    <div>
      <div className={showDialog ? styles.DialogMask : ''}></div>
      <div className={showDialog ? styles.DialogContainer : `${styles.DialogContainer} ${styles.DialogContainerHidden}`}>
        <img draggable="false" src="https://tc-cdn.123u.com/official-website/company/arrow_left.png" className={styles.DialogArrow} onClick={() => handleClickArrow('left', 1)}></img>
        <div className={styles.DialogWrapper}>
          <div className={styles.DialogCloseBtn}>
            <CloseOutlined onClick={() => { props.closeDialog(false) }} />
          </div>
          <div className={styles.PersonSlider}>
            {personLists}
          </div>
        </div>
        <img draggable="false" src="https://tc-cdn.123u.com/official-website/company/arrow_right.png" className={styles.DialogArrow} onClick={() => handleClickArrow('right', 1)}></img>
      </div>
    </div>

  )
}

Dialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  personInfo: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  changeIndex: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
}
