import React, { useCallback } from 'react'
import Banner from '@/components/Banner'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

function SocialRecruit() {
  const { t } = useTranslation()
  const bannerList = [
    {
      tabTitle: '111',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/recruit/banner.jpg',
      mobileUrl:
        'https://tc-cdn.123u.com/official-website/mobile/recruit/header.png',
    },
  ]
  const hireList = [
    {
      name: t('tech'),
      number: 20,
      url: 'https://huanle.jobs.feishu.cn/index?category=6791702736615426317',
    },
    {
      name: t('game design'),
      number: 15,
      url: 'https://huanle.jobs.feishu.cn/index?category=6791702736615409933',
    },
    {
      name: t('art design'),
      number: 25,
      url: 'https://huanle.jobs.feishu.cn/index?category=6791702736615344397',
    },
    {
      name: t('game publication'),
      number: 8,
      url: 'https://huanle.jobs.feishu.cn/index?category=6791702736615393549',
    },
    {
      name: t('all'),
      number: 0,
      url: 'https://huanle.jobs.feishu.cn/index',
    },
  ]
  const gameList = [
    // 'https://tc-cdn.123u.com/official-website/recruit/yxs.png',
    // 'https://tc-cdn.123u.com/official-website/recruit/dn.png',
    // 'https://tc-cdn.123u.com/official-website/recruit/jjsg.png',
    'https://tc-cdn.123u.com/official-website/recruit/ms.png',
    // 'https://tc-cdn.123u.com/official-website/recruit/ro.jpg',
  ]

  const workSpacePic = [
    {
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/recruit/bottombanner1.jpg',
      contentPosition: 'bottom-left',
    },
    {
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/recruit/bottombanner2.jpg',
      contentPosition: 'bottom-right',
    },
    {
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/recruit/bottombanner3.jpg',
      contentPosition: 'bottom-right',
    },
  ]
  const handleClickItem = useCallback((url) => {
    if (url !== undefined) {
      window.open(url)
    }
  }, [])

  return (
    <div>
      <div className={styles.Banner}>
        <Banner bannerList={bannerList} autoplay />
      </div>
      <div className={styles.Hire}>
        <div className={styles.HireTitle}>{t('social recruit')}</div>
        <p></p>
        <div className={styles.JobList}>
          {hireList.map((e) => (
            <div className={styles.JobContainer} key={e.name}>
              <div className={styles.JobLeft}>
                <div className={styles.JobName}>{e.name}</div>
                <div className={styles.JobNumber}>
                  {t('job count', { jobNumber: e.number })}
                </div>

                <div
                  className={styles.Btn}
                  onClick={handleClickItem.bind(this, e.url)}
                >
                  <button type="button">
                    {e.number !== 0
                      ? t('total job count', { count: e.number })
                      : t('view')}
                  </button>
                </div>
              </div>
              <div className={styles.JobRight}>
                <div className={styles.MobileBtn}>
                  <button
                    type="button"
                    onClick={handleClickItem.bind(this, e.url)}
                  >
                    {t('view')}
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className={styles.ShowAllJob}>
            <button
              type="button"
              onClick={handleClickItem.bind(
                this,
                'https://huanle.jobs.feishu.cn/index'
              )}
            >
              {t('view all jobs')}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.WhoAmI} id="aboutUs">
        <div className={styles.WhoAmITitle}>{t('who are we')}</div>
        <p></p>
        <div className={styles.WhoAmIContent}>
          {t('fever desc')}
          <br />
          {t('geek desc')}
          <br />
          {t('gamomania desc')}
          <br />
          <div className={styles.WhoAmIImgWrap}>
            <img
              src="https://tc-cdn.123u.com/official-website/recruit/who_we_are_v2.png"
              alt=""
            />
            <div className={styles.Spirits}>
              <div className={styles.SpiritItem}>{t('fever')}</div>
              <div className={styles.SpiritItem}>{t('geek')}</div>
              <div className={styles.SpiritItem}>{t('gamomania')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Games}>
        <div className={styles.GameTitle}>{t('huanle games')}</div>
        <p></p>
        {/* <GameList list={gameList} /> */}
        <div className={styles.GameImages}>
          {gameList.map((game) => (
            <img src={game} alt="" />
          ))}
        </div>
      </div>
      <div className={styles.WorkSpace}>
        <div className={styles.WorkSpaceTitle}>{t('work here')}</div>
        <p></p>
        <Banner bannerList={workSpacePic} autoplay loop />
      </div>
    </div>
  )
}

export default React.memo(SocialRecruit)
