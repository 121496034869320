import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { renderRoutes } from 'react-router-config'
import {useTranslation} from 'react-i18next'
import { useHistory } from 'react-router'

function Layout(props) {
  const {t} = useTranslation()
  const { route } = props
  const menuList = [
    {
      name: t('social recruit'),
      path: '/hire/socialRecruit',
    },
    // {
    //   name: '校园招聘',
    //   path: '/campus/campusRecruit',
    //   target: '_blank',
    // },
    {
      name: t('who are we'),
      path: '/hire/socialRecruit#aboutUs',
    },
    {
      name: t('back'),
      path: '/',
    },
  ]

  const scrollToAnchor = useCallback((hash) => {
    if (hash) {
      const dom = document.getElementById(hash)
      if (dom) {
        const height = dom.offsetTop
        window.scrollTo({ top: height, behavior: 'smooth' })
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  })

  const history = useHistory()

  window.addEventListener('load', () => {
    const hash = document.location.href.split('#')[1]
    scrollToAnchor(hash)
  })

  useEffect(() => {
    history.listen((url) => {
      let { hash } = url
      if (hash) {
        hash = hash.slice(1)
      }
      scrollToAnchor(hash)
    })
  }, [])

  return (
    <>
      <Header menuList={menuList} />
      {renderRoutes(route.routes)}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  route: PropTypes.shape({ routes: PropTypes.arrayOf(PropTypes.object) })
    .isRequired,
}

export default React.memo(Layout)
