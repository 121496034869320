import React, { memo, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Spin } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import NewsHead from './components/NewsHead'
import NewsTab from './components/NewsTab'
import styles from './style.module.scss'
import useFetchDetail from './hooks/useFetchDetail'
import richTextStyle from './richTextStyle.module.scss'

function NewsDetail() {
  const { newsDetail, loading } = useFetchDetail()
  const history = useHistory()
  const location = useLocation()
  const lng = location.pathname.split('/')[1]

  const onClickPrevious = useCallback(() => {
    if (newsDetail.other_info.previous_info.id) {
      history.push(
        `/${lng}/news-detail/${newsDetail.other_info.previous_info.id}`
      )
    }
  }, [history, lng, newsDetail.other_info.previous_info.id])

  const onClickNext = useCallback(() => {
    if (newsDetail.other_info.next_info.id) {
      history.push(`/${lng}/news-detail/${newsDetail.other_info.next_info.id}`)
    }
  }, [history, lng, newsDetail.other_info.next_info.id])

  return (
    <>
      <NewsHead />
      <NewsTab />
      <Spin spinning={loading}>
        <div className={styles.NewsDetail}>
          <div className={styles.NewsDetailWrap}>
            <div className={styles.Title}>{newsDetail.title}</div>
            <div className={styles.Time}>{newsDetail.time}</div>
            <div
              className={richTextStyle.Body}
              dangerouslySetInnerHTML={{ __html: newsDetail.content }}
            />
          </div>
          <div className={styles.BottomArea}>
            <div
              className={`${styles.PreviousNews} ${
                newsDetail.other_info.previous_info.id
                  ? ''
                  : styles.PreviousNewsDisabled
              }`}
              onClick={onClickPrevious}
            >
              <div className={styles.Indicator}>
                <ArrowLeftOutlined />
                <div>上一篇</div>
              </div>
              <div>
                {newsDetail.other_info.previous_info.id
                  ? newsDetail.other_info.previous_info.title
                  : '没有了'}
              </div>
            </div>
            <div
              className={`${styles.NextNews} ${
                newsDetail.other_info.next_info.id
                  ? ''
                  : styles.NextNewsDisabled
              }`}
              onClick={onClickNext}
            >
              <div className={styles.Indicator}>
                <div>下一篇</div>
                <ArrowRightOutlined />
              </div>
              <div>
                {newsDetail.other_info.next_info.id
                  ? newsDetail.other_info.next_info.title
                  : '没有了'}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  )
}

export default memo(NewsDetail)
