import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getUrlParams } from '@/utils'
import React from 'react'
import styles from './style.module.scss'

function Footer() {
  const { t } = useTranslation()
  const urlParams = getUrlParams()
  let { hostname } = urlParams
  const location = useLocation()
  const lng = location.pathname.split('/')[1]
  if (!hostname) {
    hostname = window.location.hostname
  }

  let beianNumber = '无'
  let companyName = '无'
  let anbeiNumber = ''

  if (hostname.indexOf('huanle.com') > -1) {
    beianNumber = '沪ICP备13014419号'
    companyName = '上海欢乐互娱网络科技有限公司'
    anbeiNumber = '31011502004658'
  }
  if (hostname.indexOf('www.huanle.com') > -1) {
    beianNumber = '沪ICP备13014419号-1'
    companyName = '上海欢乐互娱网络科技有限公司'
    anbeiNumber = '31011502004658'
  }
  if (hostname.indexOf('123u.com') > -1) {
    beianNumber = '沪ICP备17003506号'
    companyName = '欢乐互娱（上海）科技股份有限公司'
    anbeiNumber = '31011502004658'
  }
  if (hostname.indexOf('rexuewlkj.com') > -1) {
    beianNumber = '沪ICP备2022023217号-1'
    companyName = '上海热血网络科技有限公司'
    anbeiNumber = '31011502019613'
  }
  if (hostname.indexOf('huanlecdn.com') > -1) {
    beianNumber = '沪ICP备13014419号-1'
    companyName = '上海欢乐互娱网络科技有限公司'
    anbeiNumber = '31011502402148'
  }

  const date = new Date()
  const year = date.getFullYear()

  return (
    <div>
      <div className={styles.PCFooterContainer}>
        <div className={styles.Split}>
          <div className={styles.Right}>
            <ul className={styles.Nav}>
              <li>
                <a href="https://passport.huanle.com/html/useragreement.html">
                  {t('privacy')}
                </a>
              </li>
              |
              <li>
                <a href="company">{t('about us')}</a>
              </li>
              |
              <li>
                <a href="report">{t('report')}</a>
              </li>
              |
              <li>
                <a href="contact">{t('contact')}</a>
              </li>
              |
              <li>
                <a href="/hire/socialRecruit" rel="noreferrer">
                  {t('join us')}
                </a>
              </li>
            </ul>
            {lng === 'zhCn' && (
              <>
                <div className={styles.RightItem}>
                  <span>网络文化经营许可证：</span>
                  沪网文〔2016〕2895-199号 增值电信业务许可证：沪B2-20130099
                </div>
                <div className={styles.RigthItem}>
                  <span>备案号：</span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="http://beian.miit.gov.cn"
                  >
                    {beianNumber}
                  </a>
                </div>
                <div className={styles.RightItem}>
                  <span>
                    互联网违法不良信息举报电话：13818137354
                    互联网违法不良信息举报邮箱：jubao@huanle.com
                  </span>
                </div>
                <div className={styles.RightItem}>
                  <span>文化部网络游戏举报网站：</span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="http://jbts.mct.gov.cn"
                  >
                    jbts.mct.gov.cn
                  </a>{' '}
                  纠纷处理方式：联系客服或依《用户协议》约定方式处理&nbsp;&nbsp;
                  家长监护工程专线电话:021-55698900
                </div>
                <div className={styles.RightItem}>
                  Copyright © 2013- {year} All Rights Reserved&nbsp;
                  {companyName}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <img
                    className={styles.Jinghui}
                    src="https://tc-cdn.123u.com/official-website/jinghui.png"
                  />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${anbeiNumber}`}
                  >
                    沪公网安备 {anbeiNumber}号
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        {lng === 'zhCn' && (
          <div className={styles.Tips}>
            抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。
          </div>
        )}
      </div>
      {lng === 'zhCn' && (
        <div className={styles.MobileFooterContainer}>
          <div className={styles.Item}>
            <img
              className={styles.Jinghui}
              src="https://tc-cdn.123u.com/official-website/jinghui.png"
            />
            <a
              target="_blank"
              rel="noreferrer"
              href={`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${anbeiNumber}`}
            >
              沪公网安备 {anbeiNumber}号
            </a>
          </div>
          <div className={styles.Item}>增值电信业务许可证：沪B2-20130099</div>
          <div className={styles.Item}>
            <a target="_blank" rel="noreferrer" href="http://beian.miit.gov.cn">
              {beianNumber}
            </a>
          </div>
          <div className={styles.Item}>沪网文〔2016〕2895-199号</div>
          <div className={styles.Item}>
            Copyright © 2013- {year} All Rights Reserved&nbsp;
            {companyName}
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(Footer)
