import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useHistory } from 'react-router-dom'
import { Pagination, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import NewsTab from './components/NewsTab'
import NewsItem from './components/NewsItem'
import NewsHead from './components/NewsHead'
import useFetchList from './hooks/useFetchList'

const News: FC = () => {
  const { t } = useTranslation()
  const {
    data: newsList,
    page: pageInfo,
    loading,
    changeQueryParams,
  } = useFetchList()

  const history = useHistory()
  const [activeKey, setActiveKey] = useState('all')

  const setHash = useCallback(
    (hash: string) => {
      if (hash) {
        setActiveKey(hash)
        changeQueryParams({
          page: 1,
          size: 10,
          category: hash.substring(1),
        })
      } else {
        setActiveKey('all')
        changeQueryParams({
          page: 1,
          size: 10,
          category: 'all',
        })
      }
    },
    [changeQueryParams]
  )

  useEffect(() => {
    const { hash } = window.location
    setHash(hash)
    history.listen((url) => {
      const { hash: urlHash } = url
      setHash(urlHash)
    })
  }, [history, setHash])

  const handlePageChange = useCallback(
    (page: number, pageSize = 10): void => {
      changeQueryParams({
        page,
        size: pageSize,
        category: activeKey,
      })
    },
    [activeKey, changeQueryParams]
  )

  const annualList = useMemo(
    () => [
      {
        title: '2023',
        url: 'https://tc-cdn.123u.com/official-website/annuals/第四期向阳花年刊.pdf',
        cover: 'https://tc-cdn.123u.com/official-website/annuals/cover4.jpg',
      },
      {
        title: '2022',
        url: 'https://tc-cdn.123u.com/official-website/annuals/第三期向阳花年刊.pdf',
        cover: 'https://tc-cdn.123u.com/official-website/annuals/cover3.jpg',
      },
      {
        title: '2021',
        url: 'https://tc-cdn.123u.com/official-website/annuals/第二期向阳花年刊.pdf',
        cover: 'https://tc-cdn.123u.com/official-website/annuals/cover2.jpg',
      },
      {
        title: '2020',
        url: 'https://tc-cdn.123u.com/official-website/annuals/第一期向阳花年刊.pdf',
        cover: 'https://tc-cdn.123u.com/official-website/annuals/cover1.jpg',
      },
    ],
    []
  )

  return (
    <>
      <NewsHead />
      <NewsTab />
      {activeKey === '#annual' ? (
        <div className={styles.AnnualContainer}>
          <div className={styles.PCAnnualList}>
            {annualList.map((annual) => (
              <div
                className={styles.Annual}
                onClick={() => {
                  window.open(annual.url)
                }}
              >
                <div className={styles.AnnualTitle}>{annual.title}</div>
                <div className={styles.AnnualCover}>
                  <img src={annual.cover} />
                </div>
              </div>
            ))}
          </div>
          <div className={styles.MobileAnnualList}>
            {annualList.map((annual) => (
              <div
                className={styles.Annual}
                onClick={() => {
                  window.open(annual.url)
                }}
              >
                <div className={styles.AnnualTitle}>{annual.title}</div>
                <div className={styles.AnnualCover}>
                  <img src={annual.cover} />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Spin spinning={loading}>
          <div className={styles.NewsContent}>
            {newsList.length > 0 && (
              <div className={styles.NewsItemWrap}>
                {!!newsList.length &&
                  newsList.map((item) => <NewsItem key={item.id} {...item} />)}
                {!!newsList.length && (
                  <div className={styles.PaginationWrap}>
                    <Pagination
                      onChange={handlePageChange}
                      hideOnSinglePage
                      defaultCurrent={1}
                      total={pageInfo.totalCount}
                    />
                  </div>
                )}
              </div>
            )}
            {newsList.length === 0 && (
              <div className={styles.NoNews}>
                <img src="https://techcenter-common-storage.123u.com/huanle-club/client-images/public/search-no-data.png"></img>
                <div className={styles.NoNewsTip}>{t('no posts')}</div>
              </div>
            )}
          </div>
        </Spin>
      )}
    </>
  )
}

export default memo(News)
