import produce, { enableES5 } from 'immer'
import * as actionTypes from './constants'
import { StoreState, ActionTypes } from './typings'

enableES5()

const defaultState: StoreState = {
  newsList: [],
  newsListPageInfo: {},
  newsDetail: {
    content: '',
    other_info: {
      previous_info: { id: 0, title: '' },
      next_info: { id: 0, title: '' },
    },
  },
  newsListLoading: false,
}

export default (
  state: StoreState = defaultState,
  action: ActionTypes
): StoreState =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.CHANGE_NEWS_LIST: {
        draft.newsList = action.data.list
        draft.newsListPageInfo = action.data.pageInfo
        draft.newsListLoading = false
        break
      }
      case actionTypes.CHANGE_NEWS_DETAIL: {
        draft.newsDetail = action.data
        break
      }
      default:
        break
    }
  })
