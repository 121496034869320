import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation, NavLink, useHistory } from 'react-router-dom'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

interface Props {
  showHome: boolean
  showExpand: boolean
  defaultLogo: string
  menuList: any
}

function Header(props: Props) {
  const { showHome, showExpand, defaultLogo } = props
  const { t, i18n } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isMobileMenuShow, setIsMobileMenuShow] = useState(false)
  const [offsetZero, setOffsetZero] = useState(true)
  const history = useHistory()

  const location = useLocation()
  const lng = location.pathname.split('/')[1]

  const lngMap = useMemo(
    () =>
      new Map([
        ['zhCn', '中文'],
        ['zhTrans', '中文繁體'],
        ['enUs', 'English'],
        // ['in', 'Hindi'],
        // ['ko', '한국인'],
        // ['th', 'ภาษาไทย'],
      ]),
    []
  )

  let { menuList } = props
  if (showHome) {
    menuList = [
      ...[
        {
          name: t('homepage'),
          path: '/home',
        },
      ],
      ...menuList,
    ]
  }

  const handleMouseOver = useCallback(() => {
    setIsExpanded(true)
  }, [])
  const handleMouseLeave = useCallback(() => {
    setIsExpanded(false)
  }, [])

  const handleMenuItemClick = useCallback(
    (to, target) => {
      if (target === '_blank') {
        if (to.indexOf('://') >= 0) {
          window.open(to)
        } else {
          window.open(`/${lng}${to}`)
        }
      } else {
        history.push(`/${lng}${to}`)
      }
    },
    [history, lng]
  )

  const onScroll = useCallback((e) => {
    const scrollTop =
      (e.srcElement ? e.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (e.srcElement ? e.srcElement.body.scrollTop : 0)
    setOffsetZero(scrollTop === 0)
  }, [])

  const onChangeLng = useCallback(
    (selectedLng) => {
      i18n.changeLanguage(selectedLng)
      localStorage.setItem('i18nextLng', selectedLng)
      const tmp = location.pathname.split('/')
      tmp[1] = selectedLng
      console.log(tmp)
      window.location.replace(tmp.join('/'))
    },
    [i18n, location.pathname]
  )

  const logo = useMemo(
    () =>
      lng === 'zhCn' || lng === 'zhTrans' ? (
        <img src="https://tc-cdn.123u.com/official-website/logo/joy_u.png" />
      ) : (
        <img src="https://tc-cdn.123u.com/official-website/logo/joy_u_en.png" />
      ),
    [lng]
  )

  const logoWhite = useMemo(
    () =>
      lng === 'zhCn' || lng === 'zhTrans' ? (
        <img src="https://tc-cdn.123u.com/official-website/logo/joy_u_white.png" />
      ) : (
        <img src="https://tc-cdn.123u.com/official-website/logo/joy_u_en_white.png" />
      ),
    [lng]
  )

  useEffect(() => {
    document.addEventListener('scroll', onScroll)
  }, [onScroll])

  return (
    <div
      className={`${styles.HeaderContainer} ${
        (!offsetZero ||
          defaultLogo === 'colorful' ||
          isMobileMenuShow ||
          (showExpand && isExpanded)) &&
        styles.HeaderContainerWhite
      }`}
    >
      <div className={styles.LogoMobile}>
        {lng === 'zhCn' || lng === 'zhTrans' ? (
          <img src="https://tc-cdn.123u.com/official-website/logo/joy_u.png" />
        ) : (
          <img src="https://tc-cdn.123u.com/official-website/logo/joy_u_en.png" />
        )}
      </div>
      <Space className={styles.RightArea} size={30}>
        <Dropdown
          overlay={
            <Menu>
              {Array.from(lngMap).map((kv) => (
                <Menu.Item key={kv[0]} onClick={() => onChangeLng(kv[0])}>
                  {kv[1]}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <div className={styles.LngDark}>
            <img
              className={styles.LngIcon}
              src="https://tc-cdn.123u.com/official-website/public/language.png"
            />
            <div className={styles.LngName}>{lngMap.get(lng)}</div>
          </div>
        </Dropdown>
        <div
          className={styles.MenuBtn}
          onClick={() => setIsMobileMenuShow(!isMobileMenuShow)}
        >
          {isMobileMenuShow ? (
            <CloseOutlined
              style={{
                fontSize: 20,
                color: 'black',
                display: 'block',
              }}
            />
          ) : (
            <MenuOutlined
              style={{
                fontSize: 20,
                color: 'black',
                display: 'block',
              }}
            />
          )}
        </div>
      </Space>
      <div className={styles.HeaderTop}>
        <div className={styles.Logo}>
          {!offsetZero ||
          defaultLogo === 'colorful' ||
          (showExpand && isExpanded)
            ? logo
            : logoWhite}
        </div>
        <div className={styles.Menu}>
          {menuList.map((item: any) => (
            <NavLink
              className={styles.MenuTitle}
              key={item.name}
              to={`/${lng}${item.path}`}
              onClick={(e) => {
                if (!item.target) {
                  e.preventDefault()
                }
              }}
              target={item.target}
              activeClassName={`${showExpand && isExpanded && styles.Active}`}
              onFocus={() => 0}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
            >
              {item.name}
            </NavLink>
          ))}
        </div>
        <Dropdown
          overlay={
            <Menu>
              {Array.from(lngMap).map((kv) => (
                <Menu.Item key={kv[0]} onClick={() => onChangeLng(kv[0])}>
                  {kv[1]}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <div
            className={!offsetZero || isExpanded ? styles.LngDark : styles.Lng}
          >
            {!offsetZero || isExpanded ? (
              <img
                className={styles.LngIcon}
                src="https://tc-cdn.123u.com/official-website/public/language.png"
              />
            ) : (
              <img
                className={styles.LngIcon}
                src="https://tc-cdn.123u.com/official-website/public/language_white.png"
              />
            )}
            <div className={styles.LngName}>{lngMap.get(lng)}</div>
          </div>
        </Dropdown>
      </div>
      <div
        className={`${styles.ExpandView}  ${
          showExpand && isExpanded && styles.ExpandViewExpanded
        }`}
      >
        <div
          className={styles.MenuColumns}
          onFocus={() => 0}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          <div className={styles.Logo}></div>
          {menuList.map((item: any) => (
            <div className={styles.MenuColumn} key={item.name}>
              {item.children &&
                item.children.length > 0 &&
                item.children.map((child: any) => {
                  if (child.path) {
                    return (
                      <div
                        className={styles.MenuItem}
                        key={child.name}
                        onClick={() =>
                          handleMenuItemClick(child.path, child.target)
                        }
                      >
                        {child.name}
                      </div>
                    )
                  }
                  return (
                    <div
                      className={styles.MenuItemWithoutLink}
                      key={child.name}
                    >
                      {child.name}
                    </div>
                  )
                })}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${styles.MobileMenu} ${
          isMobileMenuShow && styles.MobileMenuShow
        }`}
      >
        {menuList.map((item: any) => (
          <NavLink
            onClick={() => setIsMobileMenuShow(false)}
            className={styles.MobileMenuTitle}
            key={item.name}
            to={`/${lng}${item.path}`}
            activeClassName={`${isMobileMenuShow && styles.MobileActiveTitle}`}
            target={item.target}
          >
            {item.name}
          </NavLink>
        ))}
      </div>
    </div>
  )
}

Header.propTypes = {
  showExpand: PropTypes.bool,
  showHome: PropTypes.bool,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
          target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
        })
      ),
    })
  ),
  defaultLogo: PropTypes.oneOf(['white', 'colorful']),
}

Header.defaultProps = {
  showExpand: false,
  showHome: false,
  menuList: [],
  defaultLogo: 'white',
}

export default React.memo(Header)
