import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './style.module.scss'

function NewsCard(props) {
  const { id, title, date, coverImg } = props

  const history = useHistory()
  const location = useLocation()
  const lng = location.pathname.split('/')[1]

  const handleClick = useCallback(() => {
    history.push(`/${lng}/news-detail/${id}`)
  }, [history, id, lng])

  return (
    <div className={styles.Item} onClick={handleClick}>
      <div className={styles.ImageWrap}>
        <img src={coverImg} />
      </div>
      <div className={styles.ItemTitle}>{title}</div>
      <div className={styles.ItemTime}>{date}</div>
    </div>
  )
}

NewsCard.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  coverImg: PropTypes.string.isRequired,
}

export default memo(NewsCard)
