import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '@/store/reducer'
import { actionCreators } from '../store'

function useFetchList() {
  const newsList = useSelector((state: AppState) => state.news.newsList)
  const pageInfo = useSelector((state: AppState) => state.news.newsListPageInfo)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng') || 'zhCn'

  const changeQueryParams = useCallback(
    (params: { page: number; size: number; category: string }) => {
      const tmp = { ...params }
      const fetchData = async (query: any) => {
        setLoading(true)
        await dispatch(actionCreators.getNewsList({ ...query, lng: language }))
        setLoading(false)
      }
      fetchData(tmp)
    },
    [dispatch, language]
  )

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
  }, [newsList])

  return {
    data: newsList,
    page: pageInfo,
    loading,
    changeQueryParams,
  }
}

export default useFetchList
