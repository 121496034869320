import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import jquery from 'jquery'
import Share from '@/utils/share'
import { isWXBrowser } from '@/utils'
import App from './App'

window.$ = jquery

const url = window.location.href

const testIsWxBrowser = isWXBrowser()
if (testIsWxBrowser && url.indexOf('campusRecruit') === -1) {
  try {
    Share.updateShareData()
  } catch (e) {
    console.warn(e)
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
