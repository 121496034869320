import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enUsTrans from './en-us.json'
import inTrans from './in.json'
import koTrans from './ko.json'
import thTrans from './th.json'
import zhCnTrans from './zh-cn.json'
import zhTranditional from './zh-traditional.json'

let currentLang = localStorage.getItem('i18nextLng')

if (!currentLang) {
  currentLang = 'zhCn'
}

i18n
  .use(initReactI18next) // init i18next
  .init({
    // 引入资源文件
    resources: {
      zhCn: {
        translation: zhCnTrans,
      },
      enUs: {
        translation: enUsTrans,
      },
      ko: {
        translation: koTrans,
      },
      zhTrans: {
        translation: zhTranditional,
      },
      in: {
        translation: inTrans,
      },
      th: {
        translation: thTrans,
      },
    },
    // 选择默认语言，选择内容为上述配置中的key，即en/zh
    fallbackLng: currentLang,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
