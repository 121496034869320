import React, { memo, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './style.module.scss'

interface Props {
  title: string
  id: number
  abstract: string
  time: string
  coverImg: string
}

function NewsItem(props: Props): JSX.Element {
  const { id, title, abstract, time, coverImg } = props
  const history = useHistory()
  const location = useLocation()
  const lng = location.pathname.split('/')[1]

  const handleClick = useCallback(() => {
    console.log(`/${lng}/news-detail/${id}`)
    history.push(`/${lng}/news-detail/${id}`)
  }, [id, history, lng])

  return (
    <div className={styles.NewsItem} onClick={handleClick}>
      <div className={styles.BorderWrap}>
        <div
          className={styles.ImgWrap}
          style={{ backgroundImage: `url('${coverImg}')` }}
        />
        <div className={styles.ItemContent}>
          <div className={styles.NewsTitle}>{title}</div>
          <div className={styles.NewsTime}>{time}</div>
          <div className={styles.NewsAbstract}>{abstract}</div>
          <div className={styles.NewsMore}>READ MORE</div>
        </div>
      </div>
    </div>
  )
}

export default memo(NewsItem)
