import React, { useCallback, useState, useMemo, useEffect } from 'react'
import Share from '@/utils/share'
import Banner from '@/components/Banner'
import { useHistory } from 'react-router'
import { isWXBrowser } from '@/utils'
import Tip from './components/Tip'
import styles from './style.module.scss'

const testIsWxBrowser = isWXBrowser()

function CampusRecruit(): JSX.Element {
  const [tipVisible, setTipVisible] = useState(false)
  const history = useHistory()

  const showShareTips = useCallback(async () => {
    _hmt.push(['_trackEvent', 'pop', 'click', 'click share'])
    try {
      Share.updateShareData({
        title: '十万大奖等你拿！',
        desc: '西安高校游戏策划大赛暨欢乐互娱2021校园招聘',
        imgUrl: 'https://tc-cdn.123u.com/official-website/logo/logo_square.png',
      })
    } catch (e) {
      console.warn(e)
    }
    setTipVisible(true)
  }, [])

  useEffect(() => {
    if (testIsWxBrowser) {
      try {
        Share.updateShareData({
          title: '十万大奖等你拿！',
          desc: '西安高校游戏策划大赛暨欢乐互娱2021校园招聘',
          imgUrl:
            'https://tc-cdn.123u.com/official-website/logo/logo_square.png',
        })
      } catch (e) {
        console.warn(e)
      }
    }
  }, [])

  const handleClickDeliver = useCallback(() => {
    _hmt.push(['_trackEvent', 'nav', 'click', 'campus deliver'])
    window.open(
      'https://huanle.job.kundou.cn/index/?keywords=%E6%A0%A1%E6%8B%9B&category=&location=&project=&type=&job_hot_flag=&current=1&limit=10'
    )
  }, [])

  const bannerList = useMemo(
    () => [
      {
        tabTitle: '1',
        type: 'image',
        url: 'https://tc-cdn.123u.com/official-website/recruit/01_v2.jpg',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/mobile/recruit/01_v2.jpg',
        content: (
          <div className={styles.PageContainer1}>
            <div className={styles.TextArea}>
              <img
                className={styles.Slogan}
                src="https://tc-cdn.123u.com/official-website/recruit/01_slogan.png"
              />
              <img
                className={styles.Title}
                src="https://tc-cdn.123u.com/official-website/recruit/01_title.png"
              />
            </div>
            <div className={styles.MoreArrow}>
              <img
                src="https://tc-cdn.123u.com/official-website/recruit/more_arrow_blue.png"
                alt="向下滑动查看更多"
              />
            </div>
          </div>
        ),
      },
      {
        tabTitle: '2',
        type: 'image',
        url: 'https://tc-cdn.123u.com/official-website/recruit/02_v3.jpg',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/mobile/recruit/02_v4.jpg',
        content: (
          <div className={styles.PageContainer2}>
            <div className={styles.Content}>
              <picture className={styles.FadeClass}>
                <source
                  className={styles.TextImage}
                  srcSet="https://tc-cdn.123u.com/official-website/recruit/02_text.png"
                  media="(min-width:1025px)"
                />
                <source
                  className={styles.TextImage}
                  srcSet="https://tc-cdn.123u.com/official-website/mobile/recruit/02_text.png"
                  media="(max-width:1024px)"
                />
                <img
                  className={styles.TextImage}
                  src="https://tc-cdn.123u.com/official-website/recruit/02_text.png"
                />
              </picture>
            </div>
            <div className={styles.MoreArrow}>
              <img
                src="https://tc-cdn.123u.com/official-website/recruit/more_arrow.png"
                alt="向下滑动查看更多"
              />
            </div>
          </div>
        ),
      },
      {
        tabTitle: '3',
        type: 'image',
        url: 'https://tc-cdn.123u.com/official-website/recruit/03_v3.jpg',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/mobile/recruit/03_v3.jpg',
        content: (
          <div className={styles.PageContainer3}>
            <img
              src="https://tc-cdn.123u.com/official-website/recruit/03_title_v2.png"
              className={styles.Title}
            />
            <picture className={styles.FadeClass}>
              <source
                className={styles.Content}
                srcSet="https://tc-cdn.123u.com/official-website/recruit/03_content.png"
                media="(min-width:1025px)"
              />
              <source
                className={styles.Content}
                srcSet="https://tc-cdn.123u.com/official-website/mobile/recruit/03_content_v4.png"
                media="(max-width:1024px)"
              />
              <img
                className={styles.Content}
                src="https://tc-cdn.123u.com/official-website/recruit/03_content.png"
              />
            </picture>
            <div className={styles.MoreArrow}>
              <img
                src="https://tc-cdn.123u.com/official-website/recruit/more_arrow.png"
                alt="向下滑动查看更多"
              />
            </div>
          </div>
        ),
      },
      {
        tabTitle: '4',
        type: 'image',
        url: 'https://tc-cdn.123u.com/official-website/recruit/04050607_v3.jpg',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/mobile/recruit/0405_v2.jpg',
        content: (
          <div className={styles.PageContainer4}>
            <img
              src="https://tc-cdn.123u.com/official-website/recruit/04_title_v3.png"
              className={styles.Title}
            />
            <picture className={styles.FadeClass}>
              <source
                className={styles.Content}
                srcSet="https://tc-cdn.123u.com/official-website/recruit/04_content_v4.png"
                media="(min-width:1025px)"
              />
              <source
                className={styles.Content}
                srcSet="https://tc-cdn.123u.com/official-website/mobile/recruit/04_content_v2.png"
                media="(max-width:1024px)"
              />
              <img
                className={styles.Content}
                src="https://tc-cdn.123u.com/official-website/recruit/04_content_v4.png"
              />
            </picture>
            <div className={styles.MoreArrow}>
              <img
                src="https://tc-cdn.123u.com/official-website/recruit/more_arrow.png"
                alt="向下滑动查看更多"
              />
            </div>
          </div>
        ),
      },
      {
        tabTitle: '5',
        type: 'image',
        url: 'https://tc-cdn.123u.com/official-website/recruit/04050607_v3.jpg',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/mobile/recruit/0405_v2.jpg',
        content: (
          <div className={styles.PageContainer5}>
            <img
              src="https://tc-cdn.123u.com/official-website/recruit/05_title_v4.png"
              className={styles.Title}
            />
            <picture className={styles.FadeClass}>
              <source
                className={styles.Content}
                srcSet="https://tc-cdn.123u.com/official-website/recruit/05_content.png"
                media="(min-width:1025px)"
              />
              <source
                className={styles.Content}
                srcSet="https://tc-cdn.123u.com/official-website/mobile/recruit/05_content.png"
                media="(max-width:1024px)"
              />
              <img
                className={styles.Content}
                src="https://tc-cdn.123u.com/official-website/recruit/05_content.png"
              />
            </picture>
            <div className={styles.MoreArrow}>
              <img
                src="https://tc-cdn.123u.com/official-website/recruit/more_arrow.png"
                alt="向下滑动查看更多"
              />
            </div>
          </div>
        ),
      },
      {
        tabTitle: 'position',
        type: 'image',
        url:
          'https://tc-cdn.123u.com/official-website/recruit/position_benefit_bg.jpg',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/mobile/recruit/position&benefit_bg.jpg',
        content: (
          <div className={styles.PageContainerPosition}>
            <img
              src="https://tc-cdn.123u.com/official-website/recruit/position_title.png"
              className={styles.Title}
            />
            <picture className={styles.FadeClass}>
              <source
                className={`${styles.Position}`}
                srcSet="https://tc-cdn.123u.com/official-website/mobile/recruit/position.png"
                media="(max-width:1024px)"
              />
              <img
                className={`${styles.Position}`}
                src="https://tc-cdn.123u.com/official-website/recruit/position.png"
              />
            </picture>
            <img
              className={`${styles.Benefit} ${styles.FadeClass}`}
              src="https://tc-cdn.123u.com/official-website/recruit/benefit.png"
            />
            <div className={styles.MoreArrow}>
              <img
                src="https://tc-cdn.123u.com/official-website/recruit/more_arrow.png"
                alt="向下滑动查看更多"
              />
            </div>
          </div>
        ),
      },
      {
        tabTitle: '6',
        type: 'image',
        url: 'https://tc-cdn.123u.com/official-website/recruit/04050607_v3.jpg',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/mobile/recruit/0607_v3.jpg',
        content: (
          <div className={styles.PageContainer6}>
            <img
              src="https://tc-cdn.123u.com/official-website/recruit/06_title_v3.png"
              className={styles.Title}
            />
            <img
              className={`${styles.Content} ${styles.FadeClass}`}
              src="https://tc-cdn.123u.com/official-website/recruit/06_content.png"
            />
            <div className={styles.MoreArrow}>
              <img
                src="https://tc-cdn.123u.com/official-website/recruit/more_arrow.png"
                alt="向下滑动查看更多"
              />
            </div>
          </div>
        ),
      },
      {
        tabTitle: '7',
        type: 'image',
        url: 'https://tc-cdn.123u.com/official-website/recruit/04050607_v3.jpg',
        mobileUrl:
          'https://tc-cdn.123u.com/official-website/mobile/recruit/0607_v3.jpg',
        content: (
          <div className={styles.PageContainer7}>
            <img
              src="https://tc-cdn.123u.com/official-website/recruit/07_title_v2.png"
              className={styles.Title}
            />
            <img
              className={`${styles.Content} ${styles.FadeClass}`}
              src="https://tc-cdn.123u.com/official-website/mobile/recruit/07_content.png"
            />
            <div className={`${styles.BtnArea} ${styles.FadeClass}`}>
              <img
                id="baidu-data-deliver"
                className={styles.Deliver}
                src="https://tc-cdn.123u.com/official-website/recruit/deliver.png"
                onClick={handleClickDeliver}
              />
              {testIsWxBrowser && (
                <img
                  id="baidu-data-share"
                  className={styles.Share}
                  src="https://tc-cdn.123u.com/official-website/recruit/share.png"
                  onClick={showShareTips}
                />
              )}
            </div>
            <img
              id="baidu-data-official-website"
              className={`${styles.Logo} ${styles.FadeClass}`}
              src="https://tc-cdn.123u.com/official-website/recruit/official_website.png"
              onClick={() => {
                _hmt.push([
                  '_trackEvent',
                  'nav',
                  'click',
                  'from campus recruit to official website',
                ])
                history.push('/')
              }}
            />
          </div>
        ),
      },
    ],
    [handleClickDeliver, history, showShareTips]
  )

  return (
    <div>
      <Banner
        bannerList={bannerList}
        fadeClass={styles.FadeClass}
        direction="vertical"
        mousewheel
      />
      <Tip visible={tipVisible} onClick={() => setTipVisible(false)} />
    </div>
  )
}

export default React.memo(CampusRecruit)
