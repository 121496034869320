import api from '@/services'
import * as actionTypes from './constants'

const { getHomeNewsList } = api

export const changeHomeNewsList = (data) => ({
  type: actionTypes.CHANGE_HOME_NEWS_LIST,
  data,
})

export const getHomeNews = () => async (dispatch) => {
  try {
    const language = localStorage.getItem('i18nextLng') || 'zhCn'
    const res = await getHomeNewsList({ lng: language })
    dispatch(changeHomeNewsList(res.data))
  } catch (err) {
    console.log(err)
  }
}
