import api from '@/services'

interface Options {
  title: string
  desc: string
  imgUrl: string
  link?: string
}

const share = async (options: Options) => {
  const url = document.location.href.split('#')[0]
  const res = await api.getWXConfig({ url })
  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: 'wx7c21810ab2327637', // 必填，公众号的唯一标识
    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
    nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
    signature: res.data.signature, // 必填，签名，见附录1
    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    success() {
      // alert(JSON.stringify(msg))
    },
  })
  wx.ready(() => {
    wx.updateAppMessageShareData({
      ...options,
      success: () => {
        // alert('success')
      },
    })
    wx.updateTimelineShareData({
      ...options,
      success: () => {
        // alert('success')
      },
    })
  })
  wx.error(() => {
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    // alert(JSON.stringify(res))
  })
}

const updateShareData = async (
  options: Options = {
    title: '欢乐互娱',
    desc: '让更多人发现人生的美好',
    imgUrl: 'https://tc-cdn.123u.com/official-website/logo/logo_square.png',
  }
) => {
  options.link = document.location.href
  let haveWxSdk = false
  let script
  const existScripts = document.getElementsByTagName('script')
  Array.from(existScripts).forEach((existScript) => {
    if (existScript.src === 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js') {
      haveWxSdk = true
      share(options)
    }
  })
  if (!haveWxSdk) {
    script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js'
    document.head.appendChild(script)
    script.onload = () => {
      share(options)
    }
  }
}

export default {
  updateShareData,
}
