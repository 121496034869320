import api from '@/services'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AppState } from '@/store/reducer'
import * as actionTypes from './constants'
import {
  ChangeNewsListAction,
  ChangeNewsDetailAction,
  NewsList,
  PageInfo,
  NewsDetail,
  QueryNewsList,
  QueryNewsDetail,
} from './typings'

const {
  getNewsList: getNewsListRequest,
  getNewsDetail: getNewsDetailRequest,
} = api

export const changeNewsList = (data: {
  list: NewsList
  pageInfo: PageInfo
}): ChangeNewsListAction => ({
  type: actionTypes.CHANGE_NEWS_LIST,
  data,
})

export const changeNewsDetail = (data: NewsDetail): ChangeNewsDetailAction => ({
  type: actionTypes.CHANGE_NEWS_DETAIL,
  data,
})

export const getNewsList = (
  data: QueryNewsList
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    const res = await getNewsListRequest(data)
    dispatch(
      changeNewsList({
        list: res.data.list,
        pageInfo: res.data.pageInfo,
      })
    )
  } catch (err) {
    console.log(err)
  }
}

export const getNewsDetail = (
  data: QueryNewsDetail
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    const res = await getNewsDetailRequest(data)
    dispatch(changeNewsDetail(res.data))
  } catch (err) {
    console.log(err)
  }
}
